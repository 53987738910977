import React from 'react'
import { graphql } from 'gatsby';
import Layout from '../04 Templates/layout'

export const query = graphql`
query ContactPageQuery {
    prismic {
        allContact_forms {
          edges {
            node {
              _meta {
                uid
                type
              }
              hero_image
              hero_imageSharp {
                childImageSharp {
                  fluid(maxWidth: 1900) {
                      ...GatsbyImageSharpFluid
                  }
                }
              }  
              title
              form_fields {
                type
                label
              }
            }
          }
        }
      }
    }
`
 
  const RenderBody = ({ home, slices }) => (
    <React.Fragment>
        <div className="wrapper">
            <article>
                <div className="content content--form">
                  <form
                    className="contact-form"
                    name="contact"
                    method="post"
                    action="/success/"
                    data-netlify="true"
                    netlify-honeypot="bot-field"
                  >
                    <input type="hidden" name="bot-field" />
                    <input type="hidden" name="form-name" value="contact" />
                    <label>
                      Name*
                      <input aria-required="true" type="text" id="name" name="name" />
                    </label>
                    <label>
                      Email*
                    <input aria-required="true" type="email" id="email" name="email" />
                    </label>
                    <label>
                      Message*
                      <textarea aria-required="true" id="message" name="message"></textarea>
                    </label>
                    <button type="submit" className="button button--primary">Submit</button>
                  </form>
                </div>
            </article>
        </div>
    </React.Fragment>
  );
  
  const Contact = data => {
    const doc = data.data.prismic.allContact_forms.edges[0];
    if(!doc) return null;
    return(
      <Layout data={doc}>
          <RenderBody home={doc}/>
      </Layout>
    )
  }
  
  export default Contact;